<template>
  <div class="aboutUSAlls">
    <div class="box">
      <ul class="tabs clearfix">
        <li v-for="(tab, index) in tabsName" :key="index">
          <a
            href="#"
            class="tab-link"
            @click="tabsSwitch(index)"
            v-bind:class="{ active: tab.isActive }"
            >{{ tab.name }}</a
          >
        </li>
      </ul>

      <div class="card">
        <div class="cards" style="display: block">
          <companyProfil></companyProfil>
        </div>

        <div class="cards"><contactUs></contactUs></div>

        <div class="cards"><joinUs></joinUs></div>
      </div>
    </div>
  </div>
</template>

<script>
import companyProfil from "./companyProfile.vue";
import joinUs from "./joinUs.vue";
import contactUs from "./contactUs.vue";
export default {
  components: { companyProfil, contactUs, joinUs },
  data() {
    return {
      tabsName: [
        {
          name: "公司介绍",

          isActive: true,
        },
        {
          name: "联系我们",

          isActive: false,
        },
        {
          name: "加入我们",

          isActive: false,
        },
      ],
      active: false,
    };
  },

  methods: {
    tabsSwitch(tabIndex) {
      var tabCardCollection = document.querySelectorAll(".cards"),
        len = tabCardCollection.length;

      for (var i = 0; i < len; i++) {
        tabCardCollection[i].style.display = "none";

        this.tabsName[i].isActive = false;
      }

      this.tabsName[tabIndex].isActive = true;

      tabCardCollection[tabIndex].style.display = "block";
    },
  },
};
</script>

<style lang="less" scoped>
.aboutUSAlls {
  width: 100%;
  padding: 20px 0;
  margin: 0 auto;
  .box {
    margin: 0 auto;
  }
  .tabs {
    display: flex;
    justify-content: center;
    background-color: #f6f6f6;
    height: 95px;
    border-bottom: 1px solid #dadada;
  }
  .tabs li {
    float: left;
    margin-right: 87px;
    list-style: none;
  }

  .tabs .tab-link {
    display: block;
    width: 250px;
    height: 65px;
    text-align: center;
    line-height: 65px;
    font-size: 20px;
    color: #000;
    text-decoration: none;
  }
  .tabs .tab-link:hover {
    height: 95px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 5px solid #3d4ed0;
    transition: 0.3s;
  }
  .tabs .tab-link.active {
    height: 95px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 5px solid #3d4ed0;
    transition: 0.3s;
  }

  .card {
    width: 100%;
    margin: 0 auto;
  }

  .card .cards {
    display: none;
  }

  .clearfix:after {
    content: "";

    display: block;

    height: 0;

    clear: both;
  }
}
</style>