<template>
  <div class="joinUsAlls">
    <h1>加入我们</h1>
    <div class="joinUsFirst" v-show="joinShows">
      <div
        class="joinUsFirst1"
        v-for="(item, index) in this.joinList"
        :key="index"
      >
        <div class="joinUsFirst1-1">
          <p>{{ item.names }}</p>
          <i :class="item.icons"></i>
        </div>
        <!-- <a href="" class="joinUsFirst1-2">查看职位</a> -->
      </div>
    </div>
    <el-empty description="暂时没有职位招聘" v-show="joinShows1"></el-empty>
    <div class="joinUsSecond">
      <!-- <a href=mailto:hr@yuzhian.com.cn
        >一&nbsp;&nbsp;键&nbsp;&nbsp;申&nbsp;&nbsp;请&nbsp;&nbsp;职&nbsp;&nbsp;位</a
      > -->
      <h3>简历申请邮箱HR&nbsp;&nbsp;:hr@yuzhian.com.cn</h3>
    </div>
    <div class="joinThird">
      <div class="joinThird1">
        <div class="joinThird2">
          <div class="joinThird2Left">
            <i class="el-icon-data-analysis"></i>
          </div>
          <div class="joinThird2Right">
            <h2>弹性工作 -</h2>
            <p>一周工作五天，朝九晚六，弹性打卡，每日工作满八个小时即可</p>
          </div>
        </div>
        <div class="joinThird2">
          <div class="joinThird2Left">
            <i class="el-icon-fork-spoon"></i>
          </div>
          <div class="joinThird2Right">
            <h2>餐饮，零食福利 -</h2>
            <p>公司提供每日餐补，有大量的零食可供进食</p>
          </div>
        </div>
        <div class="joinThird2">
          <div class="joinThird2Left">
            <i class="el-icon-bicycle"></i>
          </div>
          <div class="joinThird2Right">
            <h2>旅游团建 -</h2>
            <p>不定期旅游团建，促进同事之间了解</p>
          </div>
        </div>
        <div class="joinThird2">
          <div class="joinThird2Left">
            <i class="el-icon-user"></i>
          </div>
          <div class="joinThird2Right">
            <h2>女性福利 -</h2>
            <p>每一位职场独立女性都可以拥有经期假和经期补贴</p>
          </div>
        </div>
        <!-- <div class="joinUsSecond">
          <a href="">查&nbsp;&nbsp;看&nbsp;&nbsp;更&nbsp;&nbsp;多</a>
        </div> -->
      </div>
    </div>
    <div class="joinUsFourh">
      <div class="joinUsFourhLeft">
        <img src="../../../../assets/img/aboutus/18.jpg" alt="" />
      </div>
      <div class="joinUsFourhRight">
        <div class="joinUsFourhRight2">
          <img
            src="../../../../assets/img/aboutus/19.jpg"
            alt=""
            class="joinUsFourhRight2"
          />
        </div>
        <div class="joinUsFourhRight1">
          <div class="joinUsFourhRight1-1">
            <img
              src="../../../../assets/img/aboutus/15.jpg"
              alt=""
              class="joinUsFourhRight1-1"
            />
          </div>
          <div class="joinUsFourhRight1-2">
            <img
              src="../../../../assets/img/aboutus/16.jpg"
              alt=""
              class="joinUsFourhRight1-2"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      joinList: [
        {
          names: "行政类",
          icons: "el-icon-folder-opened",
        },
        {
          names: "市场类",
          icons: "el-icon-shopping-cart-1",
        },
        {
          names: "人事",
          icons: "el-icon-user",
        },
        {
          names: "开发类",
          icons: "el-icon-monitor",
        },
        {
          names: "安全类",
          icons: "el-icon-setting",
        },
        {
          names: "财务",
          icons: "el-icon-money",
        },
      ],
      joinShows: true,
      joinShows1: false,
      chooseJion: 1,
    };
  },
  created() {
    this.chooseJion1();
  },
  methods: {
    chooseJion1() {
      if (this.chooseJion === 0) {
        this.joinShows = false;
        this.joinShows1 = true;
      }
    },
  },
};
</script>

<style lang="less" scoped>
@media only screen and (max-width: 767px) {
  .joinUsAlls {
    width: 100%;
    h1 {
      text-align: center;
    }
    .joinUsFirst {
      padding: 20px 40px;
      margin: 0 auto;
      display: flex;
      flex-wrap: wrap;
      .joinUsFirst1 {
        width: 140px;
        box-shadow: 0px 2px 2px 0px rgb(130, 127, 127);
        padding: 20px;
        margin: 20px 10px;
        .joinUsFirst1-1 {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 10px;
          p {
            font-size: 20px;
          }
          /deep/.el-icon-folder-opened {
            font-size: 20px;
          }
        }
      }
    }
    .joinUsSecond {
      margin-top: 20px;
      text-align: center;
      a {
        font-size: 22px;
        color: blue;
      }
    }
    .joinThird {
      margin: 40px 0;
      padding: 20px 40px;
      .joinThird1 {
        margin: 0 auto;
        padding: 30px 40px;
        box-shadow: 0px 1px 1px 1px rgb(130, 127, 127);
        .joinThird2 {
          padding: 20px;
          display: flex;
          flex-direction: column;
          align-items: center;
          border: 1px solid #ccc;
          margin-top: 30px;
          .joinThird2Left {
          }
          .joinThird2Right {
            h2 {
              text-align: center;
              margin-bottom: 20px;
              font-size: 16px;
            }
            p {
              text-indent: 1.5rem;
              font-size: 14px;
              line-height: 20px;
              margin-top: 10px;
            }
          }
          /deep/.el-icon-data-analysis {
            font-size: 20px;
          }
          /deep/.el-icon-bicycle {
            font-size: 20px;
          }
          /deep/.el-icon-fork-spoon {
            font-size: 20px;
          }
          /deep/.el-icon-user {
            font-size: 20px;
          }
        }
      }
    }
    .joinUsFourh {
      display: flex;
      flex-direction: column;
      margin: 20px auto;
      padding: 20px 40px;
      .joinUsFourhLeft {
        img {
          width: 81vw;
          height: 37vh;
        }
      }
      .joinUsFourhRight {
        .joinUsFourhRight1 {
          display: flex;
          flex-direction: column;
          .joinUsFourhRight1-1 {
            width: 81vw;
            height: 24vh;
          }
          .joinUsFourhRight1-2 {
            width: 81vw;
            height: 37vh;
          }
        }
        .joinUsFourhRight2 {
          margin-top: 10px;
          width: 81vw;
          height: 37vh;
        }
      }
    }
  }
}
</style>