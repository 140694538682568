<template>
  <div class="contactUsAlls">
    <div class="contactUsFirst">
      <h1>联系我们</h1>
      <h3>愉快的合作从头一次沟通开始</h3>
      <div class="contactUsFirst1">
        <div class="contactUsFirst1Left">
          <h2>成都御之安科技有限公司</h2>
          <p>网络安全服务热线：15680975517</p>
          <p>地址:成都新希望国际大厦B2，2216A</p>
          <p>邮箱:15680975517@126.com</p>
        </div>
        <div class="contactUsFirst1Right">
          <el-form
            :model="ruleForm"
            :rules="rules"
            ref="ruleForm"
            class="demo-ruleForm"
          >
            <el-form-item label="姓名" prop="name">
              <el-input v-model="ruleForm.name"></el-input>
            </el-form-item>
            <div style="display: flex">
              <el-form-item label="电话" prop="tel">
                <el-input v-model="ruleForm.tel"></el-input>
              </el-form-item>
              <el-form-item label="邮箱" prop="email" style="margin-left: 30px">
                <el-input v-model="ruleForm.email"></el-input>
              </el-form-item>
            </div>
            <el-form-item label="职位" prop="job">
              <el-input v-model="ruleForm.job"></el-input>
            </el-form-item>
            <el-form-item label="地址" prop="address">
              <el-input v-model="ruleForm.address"></el-input>
            </el-form-item>
            <el-form-item label="公司名称" prop="company">
              <el-input v-model="ruleForm.company"></el-input>
            </el-form-item>
            <el-form-item label="留言内容" prop="desc">
              <el-input
                type="textarea"
                v-model="ruleForm.desc"
                maxlength="100"
                show-word-limit
              ></el-input>
            </el-form-item>
            <el-form-item>
              <el-button
                type="primary"
                @click="submitForm('ruleForm', ruleForm)"
                >提交</el-button
              >
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
    <div class="contactUsSecond">
      <img src="../../../../assets/img/aboutus/13.jpg" alt="" />
    </div>
  </div>
</template>

<script>
import { messageManagement } from "@/apis/aboutUs/contactUs1.js";
export default {
  data() {
    return {
      ruleForm: {
        name: "",
        tel: "",
        email: "",
        job: "",
        address: "",
        company: "",
        desc: "",
      },
      rules: {
        name: [{ required: true, message: "请输入姓名", trigger: "blur" }],
        tel: [{ required: true, message: "请输入电话", trigger: "blur" }],
        email: [
          { required: true, message: "请输入邮箱地址", trigger: "blur" },
          {
            type: "email",
            message: "请输入正确的邮箱地址",
            trigger: ["blur", "change"],
          },
        ],
        job: [{ required: true, message: "请输入你的职位", trigger: "blur" }],
        address: [{ required: true, message: "请输入住址", trigger: "blur" }],
        company: [
          { required: true, message: "请输入公司名称", trigger: "blur" },
        ],
        desc: [{ required: true, message: "请填写留言内容", trigger: "blur" }],
      },
    };
  },
  methods: {
    submitForm(formName, ruleForm) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          var _this = this;
          let yy = new Date().getFullYear();
          let mm = new Date().getMonth() + 1;
          let dd = new Date().getDate();
          _this.gettime = yy + "-" + mm + "-" + dd;

          messageManagement({
            name: ruleForm.name,
            phone_number: ruleForm.tel,
            text: ruleForm.desc,
            job: ruleForm.job,
            email: ruleForm.email,
            company_name: ruleForm.company,
            address: ruleForm.address,
            create_time: _this.gettime,
          }).then((res) => {
            console.log(res);
            this.$message({
              message: "留言成功，我们会尽快联系您",
              type: "success",
            }).catch(function (err) {
              console.log(err);
            });
          });
          // fetch("http://100.10.10.9:5000/admin/message/save", {
          //   method: "post",
          //   body: JSON.stringify({
          //     name: ruleForm.name,
          //     phone_number: ruleForm.tel,
          //     text: ruleForm.desc,
          //     job: ruleForm.job,
          //     email: ruleForm.email,
          //     company_name: ruleForm.company,
          //     address: ruleForm.address,
          //     create_time: _this.gettime,
          //   }),
          //   headers: {
          //     "Content-Type": "application/json",
          //   },
          // })
          //   .then((res) => {
          //     console.log(res);
          //     this.$message({
          //       message: "留言成功，我们会尽快联系您",
          //       type: "success",
          //     });
          //   })
          //   .then(function (err) {
          //     console.log(err);
          //   });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
  },
};
</script>

<style lang="less" scoped>
@media only screen and (max-width: 767px) {
  .contactUsAlls {
    width: 100%;
    h1 {
      text-align: center;
    }
    .contactUsFirst {
      padding: 70px 40px;
      background: #f5f8ff;
      h3 {
        font-size: 14px;
        color: #666666;
        font-weight: 200;
        text-align: center;
      }
      .contactUsFirst1 {
        display: flex;
        margin-top: 40px;
        flex-direction: column;
        .contactUsFirst1Left {
          h2 {
            font-size: 16px;
            font-weight: 800;
            height: 3em;
            line-height: 3em;
            text-align: center;
          }
          p {
            text-indent: 1.5rem;
            font-size: 14px;
            line-height: 20px;
            margin-top: 10px;
          }
        }
      }
      // .contactUsFirst11 {
      //   margin: 0 auto;
      //   width: 600px;
      //   display: flex;
      //   flex-wrap: wrap;
      //   .contactUsFirst1 {
      //     display: flex;
      //     flex-direction: column;
      //     align-items: center;
      //     margin-left: 100px;
      //     margin-top: 50px;
      //     .contactUsFirst1-1 {
      //       width: 150px;
      //       height: 150px;
      //       border: 3px solid #ccc;
      //       border-radius: 50%;
      //       justify-content: center;
      //       align-items: center;
      //       text-align: center;
      //       display: flex;
      //       /deep/.el-icon-phone-outline {
      //         font-size: 42px;
      //       }
      //       /deep/.el-icon-position {
      //         font-size: 42px;
      //       }
      //       /deep/.el-icon-printer {
      //         font-size: 42px;
      //       }
      //       /deep/.el-icon-folder {
      //         font-size: 42px;
      //       }
      //     }
      //     .contactUsFirst1-1:hover {
      //       background-color: #4c84ff;
      //       transition: all 0.5s;
      //       transform: scale(1.1);
      //     }
      //     p {
      //       margin-top: 20px;
      //     }
      //   }
      // }
    }
    .contactUsSecond {
      margin-top: 40px;
      text-align: center;
      padding: 20px 40px;
      img {
        margin: 0 auto;
        width: 81vw;
        height: 37vh;
      }
    }
  }
}
</style>